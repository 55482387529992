var JSBoot = JSBoot || {};
var MantleSettings = MantleSettings || {};

var MantleGrid = (function(MantleGrid) {
  // Define once
  if (MantleGrid.Sorting) {
    return MantleGrid;
  }

  MantleGrid.Sorting = MantleGrid.Sorting || {};
  var Sorting = MantleGrid.Sorting;

  /*
   * Sorting
   */

  // eslint-disable-next-line complexity
  Sorting.sortByConfig = function(gridDataset, sortConfig) {
    // We always make a copy. Keeping the initial collection intact
    var sortedDataset = gridDataset.slice(0);

    // an empty sorts array is basically saying no sorting.
    if (_.isEmpty(sortConfig) || _.isEmpty(sortConfig.sorts)) {
      return sortedDataset;
    }

    var hide_touts = !!sortConfig.hide_touts;
    var sorts = sortConfig.sorts;

    var tout_hash = {};

    if (!hide_touts) {
      _.each(sortedDataset, function(item) {
        // if we're not hiding touts. store their initial order
        if (!item.is_product) {
          tout_hash[item.initial_order] = item;
        }
      });
    }

    // Remove the non products.
    sortedDataset = _.filter(sortedDataset, function(item) {
      return !!item.is_product;
    });

    /*
     * Sort records by multiple sort columns
     */
    // eslint-disable-next-line complexity
    sortedDataset.sort(function(item1, item2) {
      for (var i = 0; i < sorts.length; i++) {
        var sort = sorts[i];
        var sort_field = sort.field;
        var sort_ascending = !!sort.ascending;

        // The compator return values are defaulted to ascending. If we are not
        // ascending, then we multiple by -1 to flip the sign
        var flip = 1;
        if (!sort_ascending) {
          flip = -1;
        }

        // if the field is not a number, then we treat it as 0 (in the case of empty reviews).
        var value1 = Number.isNaN(item1[sort_field]) ? 0 : item1[sort_field];
        var value2 = Number.isNaN(item2[sort_field]) ? 0 : item2[sort_field];

        // neither has this field. tie.
        if (typeof value1 === 'undefined' && typeof value2 == 'undefined') {
          continue;
        }

        // Item 1 gets moved to front if undefined or less than item 2
        if (typeof value1 === 'undefined') {
          return -1 * flip;
        }
        if (value1 < value2) {
          return -1 * flip;
        }

        // Opposite. Item 2 is less or undefined, so it moves to the front.
        if (typeof value2 === 'undefined') {
          return 1 * flip;
        }
        if (value1 > value2) {
          return 1 * flip;
        }
      }
    });

    // we want the original dataset
    var total = gridDataset.length;

    var final = [];

    // keep touts in place.
    for (var i = 0; i < total; i++) {
      if (!_.isEmpty(tout_hash[i])) {
        final.push(tout_hash[i]);
        continue;
      }
      // this spot can be filled with regular item. find the next one.
      while (sortedDataset.length > 0) {
        var item = sortedDataset.shift();
        // is this necessary?
        // we already remove non products when doing the initial sort since
        // currently there are only touts and products and only products can
        // sort.
        if (!item.is_product) {
          continue;
        }
        final.push(item);
        break;
      }
    }

    if (!!JSBoot.cookie.get('grid-debug')) {
      // eslint-disable-next-line no-console
      console.log(sortConfig, final);
    }
    return final;
  };

  /*
   * Get the sort config by key. It is defined the $conf under
   *
   * mantle_grid_sort_config
   */
  // eslint-disable-next-line complexity
  Sorting.getSortConfigByKey = function(key, variant) {
    if (!key) {
      return null;
    }

    // Allow a sortString to include the variant. default:no_sold_out_reorder
    var bits = key.split(':');
    if (bits.length === 2) {
      key = bits[0];
      variant = bits[1];
    }

    var config = Sorting.getBaseSortConfigByKey(key);
    var variantConfig = null;

    if (variant) {
      variantConfig = this.processVariantConfig(config, variant);
    }

    if (variantConfig) {
      config = variantConfig;
    }

    return config;
  };

  /*
   * Get the base config.
   */
  Sorting.getBaseSortConfigByKey = function(key) {
    if (_.isEmpty(MantleSettings.mantle_grid_sort_config)) {
      return null;
    }

    var config = MantleSettings.mantle_grid_sort_config[key];
    if (!config) {
      return null;
    }

    return config;
  };

  /*
   * Get the variant config. We create a copy of the base config so that our
   * processing does not affect the base config.
   */
  Sorting.processVariantConfig = function(config, variant) {
    if (!config.variants || !config.variants[variant]) {
      return;
    }

    var variantConfig = config.variants[variant];

    // For now just merge in sorts.
    var newConfig = Object.assign({}, config);
    newConfig.sorts = variantConfig.sorts;
    return newConfig;
  };

  return MantleGrid;
})(MantleGrid || {});
