var MantleGrid = (function (MantleGrid) {
  // Define once
  if (MantleGrid.Filter) {
    return MantleGrid;
  }

  // Copy of String.prototype.startsWith
  // Remove polyfill because 3rd party libs were clashing.
  var startsWith = function (str, search, rawPos) {
    var pos = rawPos > 0 ? rawPos | 0 : 0;
    return str.substring(pos, pos + search.length) === search;
  };

  MantleGrid.Filter = MantleGrid.Filter || {};
  var Filter = MantleGrid.Filter;

  /*
   * Filter
   */

  /*
   * Simple logic to match within price ranges.
   */
  Filter.processPrice = function (all_filter_sets, namespace, filter_list, price, $gridItem, item) {
    _.each(filter_list, function (filter_obj) {
      var min = filter_obj.min;
      var max = filter_obj.max;

      if (price < min || price >= max) {
        return;
      }

      // matches price range
      Filter.addToFilterSet(all_filter_sets, namespace, filter_obj, $gridItem, item);
    });
  };

  /*
   * Simple tag membership
   */
  Filter.processTags = function (all_filter_sets, namespace, filter_list, tags, $gridItem, item) {
    _.each(filter_list, function (filter_obj) {
      var key = filter_obj.key;

      if (!_.includes(tags, key)) {
        return;
      }

      // has tag
      Filter.addToFilterSet(all_filter_sets, namespace, filter_obj, $gridItem, item);
    });
  };

  /*
   * Once a tag is matched. We both add it to all_filter_sets which is
   * essentially a registry of the matched tags within the grid.
   *
   * Then we also flag the grid item as having the filter match. This used to
   * be adding classes, now it's adding to the dataset item.
   *
   * Note we go through this multi step process.
   * 1. Defining a filter set
   * 2. Building an all_filter_sets registry
   * 3. Rendering filters from all_filter_sets
   *
   * So that we don't show 0 count filters.
   */
  Filter.addToFilterSet = function (all_filter_sets, namespace, filter_obj, $gridItem, item) {
    var key = filter_obj.key;
    var label = filter_obj.label;

    // For now we are unique ID-ing with namespace + key
    var filter_key = filter_obj['filter_key'];
    var classname = 'product-grid__item--filter--' + filter_key;

    if (!(key in all_filter_sets[namespace])) {
      all_filter_sets[namespace][key] = {
        namespace: namespace,
        key: key,
        filter_key: filter_key,
        name: label,
        classname: classname,
        count: 0
      };
    }
    all_filter_sets[namespace][key]['count']++;

    // Mark the grid item as having matched.

    if (item && item.filters) {
      item.filters[filter_key] = true;
    }
  };

  /*
   * Handles any data-filter-sets
   */
  Filter.processFilterManifest = function ($grid, item_selector, filter_manifest) {
    var $grid_items = $(item_selector, $grid);
    var all_filter_sets = {};
    var gridDataset = MantleGrid.getDataset($grid);

    // Loop through all products
    $grid_items.each(function (i, obj) {
      var $gridItem = $(obj);
      var item = MantleGrid.Dataset.getDataItemById(gridDataset, $gridItem);

      // Loop through our tags, split them by comma, then trim the whitespace
      // eslint-disable-next-line complexity
      _.each(filter_manifest, function (category) {
        var namespace = category.key;

        if (!(namespace in all_filter_sets)) {
          all_filter_sets[namespace] = {};
        }

        if (category.type === 'product_price') {
          if (!$gridItem.data('product-id')) {
            return;
          }

          var prodId = $gridItem.data('product-id');
          var prodData = prodcat.data.getProduct(prodId);
          var subtype = category.subtype;
          var defaultSku = prodData.defaultSku;
          if (_.isUndefined(defaultSku) || _.isNull(defaultSku)) {
            return;
          }
          var price = prodData.defaultSku[subtype];
          if (_.isUndefined(price) || _.isNull(price)) {
            return;
          }

          var filter_list = category.filter_list;
          Filter.processPrice(all_filter_sets, namespace, filter_list, price, $gridItem, item);
        }

        if (category.type === 'product_data') {
          if (!$gridItem.data('product-id')) {
            return;
          }

          var prodId = $gridItem.data('product-id');
          var prodData = prodcat.data.getProduct(prodId);
          var subtype = category.subtype;
          var tags = prodData[subtype];
          if (!tags) {
            return;
          }
          var filter_list = category.filter_list;
          tags = _.map(tags.split(','), _.trim);
          Filter.processTags(all_filter_sets, namespace, filter_list, tags, $gridItem, item);
        }
      });
    });

    return all_filter_sets;
  };

  // eslint-disable-next-line complexity
  Filter.filterDataset = function (gridDataset, filterQuery) {
    var filteredDataset = gridDataset.slice(0);

    if (!filterQuery || _.isEmpty(filterQuery)) {
      return filteredDataset;
    }

    var total = filteredDataset.length;

    var final = [];

    for (var i = 0; i < total; i++) {
      var item = filteredDataset[i];
      if (!item.filterable) {
        continue;
      }
      var matched = Filter.checkFilterMatch(item, filterQuery);
      if (matched) {
        final.push(item);
      }
    }

    if (!!JSBoot.cookie.get('grid-debug')) {
      console.log(filterQuery, final);
    }
    return final;
  };

  /*
   * Top level check for item matching a filterQuery.
   */
  Filter.checkFilterMatch = function (item, filterQuery) {
    var itemFilterHash = item.filters;
    return Filter.checkFilterMatchGroup(itemFilterHash, filterQuery);
  };

  /*
   * This will check whether a hash of filter tags matches a
   * filterGroup/filterQuery. This is meant to be called recursively and return
   * early where possible.
   */
  // eslint-disable-next-line
  Filter.checkFilterMatchGroup = function (itemFilterHash, filterGroup) {
    var set_type = filterGroup.__type || 'OR';
    var is_or = set_type === 'OR';
    var is_and = !is_or;

    for (var key in filterGroup) {
      // ignore system keys
      if (startsWith(key, '__')) {
        continue;
      }

      var value = filterGroup[key];
      // this is a sub group
      if (typeof value === 'object') {
        var match = Filter.checkFilterMatchGroup(itemFilterHash, value);
        // this is an and group. fail fast
        if (is_and && match === false) {
          return false;
        }
        // this is a or group. succeed early.
        if (is_or && match === true) {
          return true;
        }
      }
      // This is a filter and not a group.
      if (value === true) {
        if (is_or && itemFilterHash[key] === true) {
          return true;
        }
        if (is_and && itemFilterHash[key] !== true) {
          return false;
        }
      }
    }

    // if we're in an AND group. We didn't fail fast because we passed all the
    // subgroups. So we matched
    if (is_and) {
      return true;
    }
    // If we're in an or. We get here if we didnt match.
    return false;
  };

  return MantleGrid;
})(MantleGrid || {});
