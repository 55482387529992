var MantleGrid = (function(MantleGrid) {
  // Define once
  if (MantleGrid.Placement) {
    return MantleGrid;
  }

  MantleGrid.Placement = MantleGrid.Placement || {};
  var Placement = MantleGrid.Placement;

  /*
   * Autoplacement
   */

  /*
   * Handle IE11 which does not have autoplacement.
   */
  Placement.ie11_autoplacement = function($grid, item_selector) {
    if (MantleGrid.IEVersion !== 11) {
      return;
    }

    var $visibleItems = $(item_selector + ':visible', $grid);
    if ($visibleItems.length === 0) {
      return;
    }

    var $cssGridContainer = $visibleItems.parent();

    var columns = Placement.detectColumns($cssGridContainer);

    MantleGrid.Placement.autoplacement_dense($visibleItems, columns);
  };

  Placement.detectColumns = function($cssGridContainer) {
    // hardcoded efault
    var columns = 4;
    if ($(window).width() < 640) {
      columns = 2;
    }

    // Detect columns by parsing the css grid templates string.
    // So far only support simple dim dim dim dim  formats like
    // 300px 300px 300px
    var gridTemplate = Placement.getGridTemplate($cssGridContainer);

    if (!gridTemplate) {
      return columns;
    }

    var cols = gridTemplate.split(' ');
    columns = cols.length;
    return columns;
  };

  Placement.getGridTemplate = function($cssGridContainer) {
    var gridTemplate = $cssGridContainer.css('grid-template-columns');

    // try MS
    if (!gridTemplate) {
      gridTemplate = $cssGridContainer.css('-ms-grid-columns');
    }
    return gridTemplate;
  };

  /*
   * Replicate dense autoplacement. This works in non IE browsers without any
   * help.
   */
  Placement.autoplacement_dense = function($grid_items, columns) {
    var current_row = 1;
    var current_column = 1;
    var active_rowspans = {};

    var move_down_row = function() {
      current_row++;
      // cleanup active rowspans after every row move
      _.each(active_rowspans, function(row, key) {
        if (current_row > row) {
          delete active_rowspans[key];
        }
      });
    };

    /* eslint-disable complexity */
    $grid_items.each(function(objIndex, obj) {
      var $item = $(obj);
      var colspan = 1;
      var rowspan = 1;

      if ($item.data('grid-colspan')) {
        colspan = $item.data('grid-colspan');
      }

      if ($item.data('grid-rowspan')) {
        rowspan = $item.data('grid-rowspan');
      }

      var starting_column = current_column;

      /* eslint-disable no-constant-condition */
      while (true) {
        // find next available starting col
        if (active_rowspans[starting_column]) {
          starting_column++;
          continue;
        }

        if (starting_column > columns) {
          starting_column = 1;
          move_down_row();
          continue;
        }

        // we finally have a viable starting column
        // now we check if we have space.
        var available_space = 1;
        var test_end_index = starting_column + 1;
        while (true) {
          // hit an active rowspan
          if (active_rowspans[test_end_index]) {
            break;
          }
          // hit the end of row
          if (test_end_index > columns) {
            break;
          }
          available_space++;
          test_end_index++;
        }

        // not enough space to place colspan
        var place_item_in_row = false;
        if (colspan <= available_space) {
          place_item_in_row = true;
        }
        // case where item is bigger than columns provided.
        // will place in its own row by tiself.
        if (available_space === columns) {
          place_item_in_row = true;
        }
        if (!place_item_in_row) {
          starting_column = 1;
          move_down_row();
          continue;
        }

        // we have a valid starting spot with space on row
        break;
      }

      // note current_row was incremented during above while loop.
      // maybe use a starting_row? dont seem to need it
      current_column = starting_column;


      if (rowspan > 1) {
        for (var i = 0; i < colspan; i++) {
          active_rowspans[current_column + i] = current_row + rowspan - 1;
        }
      }

      obj.style['-ms-grid-row'] = current_row;
      obj.style['-ms-grid-row-span'] = rowspan;
      obj.style['-ms-grid-column'] = current_column;
      obj.style['-ms-grid-column-span'] = colspan;

      obj.style['grid-row'] = current_row + ' / span ' + rowspan;
      obj.style['grid-column'] = current_column + ' / span ' + colspan;

      $item.attr('grid-row', current_row);
      $item.attr('grid-row-span', rowspan);
      $item.attr('grid-column', current_column);
      $item.attr('grid-column-span', colspan);

      current_column += colspan;
    });
  };


  return MantleGrid;
})(MantleGrid || {});
