var MantleScroll = MantleScroll || {};

var MantleGrid = (function(MantleGrid) {
  // Define once
  if (MantleGrid.Animation) {
    return MantleGrid;
  }

  MantleGrid.Animation = MantleGrid.Animation || {};
  var Animation = MantleGrid.Animation;

  /*
   * Animation
   */

  var AnimationManager = function($grid, item_selector) {
    var self = this;
    this.item_selector = item_selector;
    this.$grid = $grid;
    this.mixend_timer = null;
    this.firstRun = true;

    var process_queue = _.debounce(function() {
      self.process_queue();
    });

    var handler = function(entries) {
      if (self.mixing) {
        return;
      }

      _.each(entries, function(entry) {
        if (entry.isIntersecting) {
          var elem = entry.target;
          $(elem).addClass('queue-animation');
          process_queue();
        }
      });
    };

    var options = {
      root: null,
      rootMargin: '0px',
      threshold: [0.1],
    };

    this.observer = new IntersectionObserver(handler, options);
  };

  AnimationManager.prototype.process_queue = function() {
    var $grid = this.$grid;
    var item_selector = this.item_selector;
    var $queued_items = $(item_selector + '.queue-animation:visible', $grid);
    var animation_index = 1;
    $queued_items.each(function(i, obj) {
      var $item = $(obj);
      $item.removeClass('queue-animation');
      // if offscreen, no delay.
      if (MantleScroll.offscreen($item)) {
        return;
      }
      // This is handled via css.
      $item.attr('data-grid-delay', animation_index);
      animation_index++;
    });
    $queued_items.addClass('mg-item-reveal');
  };

  /*
   * Re-add waypoint detection.
   */
  AnimationManager.prototype.initGridItemHandlers = function() {
    var $grid = this.$grid;
    var item_selector = this.item_selector;

    var grid_items = $grid[0].querySelectorAll(item_selector);

    for (var i = 0; i < grid_items.length; i++) {
      var grid_item = grid_items[i];
      this.observer.observe(grid_item);
    }
  };

  /*
   * Reset grid items to the pre-loaded state.
   */
  AnimationManager.prototype.resetAnimation = function() {
    var $grid = this.$grid;
    var item_selector = this.item_selector;

    var grid_items = $grid[0].querySelectorAll(item_selector);

    for (var i = 0; i < grid_items.length; i++) {
      var grid_item = grid_items[i];
      this.observer.unobserve(grid_item);
    }

    var $grid_items = $(item_selector, $grid);
    $grid_items.removeClass('queue-animation');
    // Do not remove loaded on first load. This is to support loaded classes
    // *before* we init grids. Also only remove laoded if we're doing an
    // animated op. L2 calls do not do animations.
    if (!this.firstRun && $grid.data('animate-op')) {
      $grid_items.removeClass('mg-item-reveal');
    }
    this.firstRun = false;
  };

  AnimationManager.prototype.mixStartHandler = function() {
    var $grid = this.$grid;
    window.clearTimeout(this.mixend_timer);
    this.mixing = true;
    $grid.addClass('mixing');
    this.resetAnimation();
  };

  AnimationManager.prototype.mixEndHandler = function() {
    // mixing class turns off animations which mess up with mixing
    // we use the timeout to mixing class gets removed after css
    // animations.
    var $grid = this.$grid;

    this.mixing = false;

    this.mixend_timer = window.setTimeout(function() {
      $grid.removeClass('mixing');
    }, 500);

    this.initGridItemHandlers();
  };

  Animation.AnimationManager = AnimationManager;

  return MantleGrid;
})(MantleGrid || {});
