var MantlePerf = MantlePerf || {};

var MantleGrid = (function(MantleGrid) {
  MantleGrid.initializeGrid = function($grid, item_selector, options) {
    var mantleGridOptions = {
      'grid': $grid,
      'item_selector': item_selector,
    };

    mantleGridOptions = _.extend({}, mantleGridOptions, options || {});

    var gridDataset = MantleGrid.Dataset.getInitialDataset($grid, item_selector);
    var mixItUpArgs = MantleGrid.MixItUp.getSettings(mantleGridOptions);
    mixItUpArgs.load.dataset = gridDataset;

    // Initialize the Sort
    if ($grid.data('sort-string')) {
      mantleGridOptions.initialSort = $grid.data('sort-string');
    }

    var $visibleItems = $(item_selector + ':visible', $grid);
    var totalCols = MantleGrid.gather_total_cols($visibleItems);
    $grid.attr('data-total-colspan', totalCols);
    $grid.data('mantleGridOptions', mantleGridOptions);

    MantleGrid.initMixItUp($grid, mixItUpArgs, mantleGridOptions);

    MantleGrid.attachEvents(mantleGridOptions);
    MantleGrid.reportGridCount(mantleGridOptions);
  };

  // easier access.
  MantleGrid.getDataset = function($grid) {
    return MantleGrid.Dataset.getDataset($grid);
  };

  MantleGrid.reportGridCount = function(mantleGridOptions) {
    var $grid = mantleGridOptions.grid;
    var item_selector = mantleGridOptions.item_selector;

    var $visibleItems = $(item_selector + ':visible', $grid);
    // Display # of filtered results
    // We send an obj that separates products from all grid items
    var gridCountInfo = {};
    gridCountInfo.all = $visibleItems.length;
    gridCountInfo.products = $visibleItems.filter(':not([data-inline-content])').length;
    $grid.trigger('mantle-grid:grid-count', gridCountInfo);
  };

  /*
   * Combines filters/sorts into a remixedDataset.
   */
  MantleGrid.remixedDataset = function($grid, filterQuery, sortString) {
    var Dataset = MantleGrid.Dataset;
    var Filter = MantleGrid.Filter;
    var Sorting = MantleGrid.Sorting;

    var gridDataset = Dataset.getDataset($grid);

    // First we filter
    var filteredDataset = Filter.filterDataset(gridDataset, filterQuery);

    // Next we sort the filtered
    var sortConfig = Sorting.getSortConfigByKey(sortString);

    var sortedDataset = Sorting.sortByConfig(filteredDataset, sortConfig);
    return sortedDataset;
  };

  /*
   * Remix the grid based on filterString and sortString
   */
  // eslint-disable-next-line complexity
  MantleGrid.remix = function($grid, filterQuery, sortString, options) {
    if (!filterQuery) {
      filterQuery = {};
    }
    if (!sortString && sortString !== false) {
      sortString = 'default';
    }

    var defaultOptions = {
      animate: true,
    };
    options = _.extend({}, defaultOptions, options || {});
    var animate = options.animate;
    var mixer = $grid.data('mixitup-mixer');

    var remixedDataset = MantlePerf.thresholdLog(function() {
      var remixedDataset = MantleGrid.remixedDataset($grid, filterQuery, sortString);
      return remixedDataset;
    }, 'MantleGrid.remixDataset');

    var mantleGridOptions = $grid.data('mantleGridOptions');
    if (typeof mantleGridOptions.remixedDatasetAlter === 'function') {
      var remixedReturn = mantleGridOptions.remixedDatasetAlter(remixedDataset, mixer, filterQuery, sortString, options);
      // Instead of just altering. Allow us to return a new array.
      if (remixedReturn) {
        remixedDataset = remixedReturn;
      }
    }

    // Right now, mixitup doesn't have a way to see if you're animating.
    $grid.data('animate-op', animate);
    MantlePerf.thresholdLog(function() {
      mixer.dataset(remixedDataset, animate);
    }, 'mixer.dataset');
  };

  MantleGrid.attachEvents = function(mantleGridOptions) {
    var $grid = mantleGridOptions.grid;
    var item_selector = mantleGridOptions.item_selector;

    $grid.off('.mantle-grid-attach');
    // Filter was selected.
    $grid.on('mantle-grid:filter-set.mantle-grid-attach', function(evt, filterQuery) {
      if ($grid.data('mixitup-mixer')) {
        $grid.data('filter-query', filterQuery);
        var filterString = JSON.stringify(filterQuery);
        $grid.attr('data-filter-string', filterString);
        $grid.trigger('mantle-grid:remix');
      }
    });

    // this resets the product grids
    $grid.on('mantle-grid:filter-reset.mantle-grid-attach', function() {
      $grid.trigger('mantle-grid:filter-set', {});
    });

    // Sort selected
    $grid.on('mantle-grid:sort-selected.mantle-grid-attach', function(evt, sortString) {
      if ($grid.data('mixitup-mixer')) {
        $grid.attr('data-sort-string', sortString);
        $grid.trigger('mantle-grid:remix');
      }
    });

    // Called by either sort/filter to remix the grid based on current sort and
    // filter settings
    $grid.on('mantle-grid:remix.mantle-grid-attach', function(e, options) {
      if ($grid.data('mixitup-mixer')) {
        var filterQuery = $grid.data('filter-query');
        var sortString = $grid.attr('data-sort-string');
        MantleGrid.remix($grid, filterQuery, sortString, options);
      }
    });
    $grid.on('mantle-grid:request-grid-count.mantle-grid-attach', function() {
      MantleGrid.reportGridCount(mantleGridOptions);
    });

    $grid.on('mantle-grid:process-filter-manifest.mantle-grid-attach', function(e, filter_manifest) {
      var all_filter_sets = MantleGrid.Filter.processFilterManifest($grid, item_selector, filter_manifest);
      return all_filter_sets;
    });
  };

  // func to init mixitup grid(s).
  /* eslint-disable complexity */
  MantleGrid.initMixItUp = function($grid, mixItUpArgs, mantleGridOptions) {
    var initialSort = null;
    var initialFilter = null;
    // Note that the mixItUpArgs.load.dataset must match the pre-rendered dom.
    // However, sometimes we don't want to show all the pre-rendered dom. That
    // is handled via initialFilter, initialSort, AND/OR initialDatasetAlter
    var initialDataset = mixItUpArgs.load.dataset;

    if (mantleGridOptions.initialSort) {
      initialSort = mantleGridOptions.initialSort;
    }

    if (mantleGridOptions.initialFilter) {
      initialFilter = mantleGridOptions.initialFilter;
    }

    var mixer = $grid.data('mixitup-mixer');

    // if mixitup was perviously setup. Destroy it first.
    // dataset only plays nicely with reiniting if we have a dataset render
    // function which we currently don't support.
    if (!!mixer) {
      // eslint-disable-next-line no-console
      console.warn('We are reiniting mixitup. This is supported but not optimal');
      mixer.destroy();
    }

    // Init mixItUp
    // eslint-disable-next-line no-undef
    mixer = mixitup($grid, mixItUpArgs);
    $grid.data('mixitup-mixer', mixer);

    // Dont' run the initialFilter/Sort if we have an intiailDatasetAlter hook.
    if ((initialSort || initialFilter) && !mantleGridOptions.initialDatasetAlter) {
      var remixedInitialDataset = MantleGrid.remixedDataset($grid, initialFilter, initialSort);
      MantlePerf.thresholdLog(function() {
        mixer.dataset(remixedInitialDataset, false);
      }, 'initMixItUp.remixedInitialDataset');
    }

    /*
     * Set the initial dataset of the grid. Note that once this grid is remixed
     * once. The initialDataset will be lost as we remix based on the
     * gridDataset (which holds all items).
     *
     * For now if you want to replicate having a custom "default" grid. You
     * also need to handle the non-filtered version returned from
     * remixedDatasetAlter.
     */
    if (mantleGridOptions.initialDatasetAlter) {
      var alteredInitialDataset = mantleGridOptions.initialDatasetAlter(initialDataset, mixer, initialFilter, initialSort);
      if (!!alteredInitialDataset) {
        MantlePerf.thresholdLog(function() {
          mixer.dataset(alteredInitialDataset, false);
        }, 'initMixItUp.alteredInitialDataset');
      }
    }

    $grid.addClass('mg-grid-loaded');

    // Only set to default if string doesn't always exist
    if (!$grid.data('sort-string')) {
      $grid.attr('data-sort-string', 'default');
    }
  };

  MantleGrid.gather_total_cols = function($elements) {
    var total_cols = 0;
    $elements.each(function(i, obj) {
      var $item = $(obj);

      var cols = 1;
      if ($item.data('grid-colspan')) {
        cols = $item.data('grid-colspan');
        cols = parseInt(cols);
      }

      total_cols += cols;
    });
    return total_cols;
  };

  return MantleGrid;
})(MantleGrid || {});
