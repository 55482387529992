var JSBoot = JSBoot || {};
var MantlePD = MantlePD || {};

var MantleGrid = (function(MantleGrid) {
  // Define once
  if (MantleGrid.Dataset) {
    return MantleGrid;
  }

  MantleGrid.Dataset = MantleGrid.Dataset || {};
  var Dataset = MantleGrid.Dataset;

  /*
   * DATASET API
   */

  /*
   * Based on the pre-rendered grid. Build an initial dataset.
   * This dataset will be modified with metadata for sorting/filtering..
   */
  Dataset.basePrerenderedDataset = function($grid, item_selector) {
    var $gridItems = $(item_selector, $grid);
    var gridDataset = [];

    $gridItems.each(function(i, obj) {
      var $gridItem = $(obj);
      $gridItem.attr('data-grid-uid', i);

      var item = {
        'id': i,
        'initial_order': i,
        'filters': {},
      };

      if ($gridItem.data('order-index')) {
        item.order_index = parseInt($gridItem.data('order-index'));
      }

      gridDataset.push(item);
    });
    return gridDataset;
  };

  /*
   * Get the first initial dataset fully formed but withou any L2 or FE only
   * data. There might be certain processes like ratings that are in flight but
   * the only guarnateed data from this call will be server generated
   * attributes like from page_data.
   */
  Dataset.getInitialDataset = function($grid, item_selector) {
    var gridDataset = Dataset.basePrerenderedDataset($grid, item_selector);
    // store dataset
    $grid.data('grid-dataset', gridDataset);

    Dataset.gridDataAlter($grid);

    return gridDataset;
  };

  /*
   * Main func that handles altering the base prerender dataset and adding
   * proper metadata.
   */
  Dataset.gridDataAlter = function($grid) {
    var gridDataset = MantleGrid.getDataset($grid);

    var $gridItems = $grid.find('[data-grid-uid]');

    var gridDatasetHash = {};

    for (var i = 0; i < gridDataset.length; i++) {
      var dataItem = gridDataset[i];
      gridDatasetHash[dataItem.id] = dataItem;
    }

    $.each($gridItems, function(i, obj) {
      var $gridItem = $(obj);
      var id = $gridItem.data('grid-uid');
      var item = gridDatasetHash[id];
      var metadata = {};
      if ($gridItem.data('product-id')) {
        var prodId = $gridItem.data('product-id');
        item.is_product = true;
        item.product_id = prodId;
      }

      // this is in its own func so we call it first.
      Dataset.initDataItem(item, $gridItem, metadata);
      // Allow brand to do their own item prep.
      $grid.trigger('mantle-grid:init-dataItem', [item, $gridItem, metadata]);
    });
  };

  /*
   * This is prepping and adding product data/manifest so that future hooks can
   * access them. As much as possible should be put into mantle_grid so that
   * all brands can share common functionality.
   */
  Dataset.initDataItem = function(item, $gridItem, metadata) {
    if (!item.is_product) {
      return;
    }

    // products will default to filterable
    item.filterable = true;
    var prodId = item.product_id;

    var $product = $gridItem;
    // Try to grab the first js-product
    var productEl = $gridItem[0].querySelector('.js-product[data-product-id="' + prodId + '"]');

    if (productEl) {
      $product = $(productEl);
    }

    var prodData = MantlePD.getProductFromEl($product);
    var pdManifest = $product.data('pd-manifest');

    // setup metadata for products
    metadata.prodData = prodData;
    metadata.pdManifest = pdManifest;

    item.product_name = prodData.PROD_RGN_NAME;

    if (prodData.defaultSku) {
      var defaultSku = prodData.defaultSku;

      if (defaultSku.PRICE) {
        item.price = defaultSku.PRICE;
      }
    }
  };

  Dataset.getDataset = function($grid) {
    var gridDataset = $grid.data('grid-dataset');
    return gridDataset;
  };

  Dataset.getDataItemById = function(gridDataset, id) {
    // Allow id to be a $gridItem
    if (JSBoot.isJQuery(id)) {
      id = id.data('grid-uid');
      if (id === null) {
        return;
      }
    }
    var match = null;
    $.each(gridDataset, function(i, item) {
      if (item.id === id) {
        match = item;
        return false;
      }
    });
    return match;
  };
  return MantleGrid;
})(MantleGrid || {});
