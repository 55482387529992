var JSBoot = JSBoot || {};
var MantlePD = MantlePD || {};
var MantlePerf = MantlePerf || {};

var MantleGrid = (function(MantleGrid) {
  // Define once
  if (MantleGrid.MixItUp) {
    return MantleGrid;
  }

  MantleGrid.MixItUp = MantleGrid.MixItUp || {};
  var MixItUp = MantleGrid.MixItUp;

  MixItUp.getSettings = function(mantleGridOptions) {
    var $grid = mantleGridOptions.grid;
    var item_selector = mantleGridOptions.item_selector;

    // mixItUp Settings
    // https://www.kunkalabs.com/mixitup/docs/configuration-object/
    var animationManager = new MantleGrid.Animation.AnimationManager($grid, item_selector);

    // Start the animation funcs
    if (!mantleGridOptions.disableStaggeredAnimation) {
      animationManager.initGridItemHandlers();
    }

    var mixItUpArgs = {
      animation: {
        duration: 500,
        effects: 'fade',
        clampHeight: false, // turn off for css grid
      },
      selectors: {
        target: item_selector,
      },
      data: {
        uidKey: 'id',
      },
      controls: {
        enable: false
      },
      layout: {
      },
      load: {
      },
      callbacks: {
        onMixStart: function(state, futureState) {
          MantlePerf.thresholdLog(function() {
            if (!mantleGridOptions.disableStaggeredAnimation) {
              animationManager.mixStartHandler();
            }
          }, 'mixStart');
        },
        onMixEnd: function(state) {
          MantlePerf.thresholdLog(function() {
            if (!mantleGridOptions.disableStaggeredAnimation) {
              animationManager.mixEndHandler();
            }
            MantleGrid.reportGridCount(mantleGridOptions);
            MantleGrid.Placement.ie11_autoplacement($grid, item_selector);
          }, 'mixEnd');
        },
      },
    };

    if (mantleGridOptions.effects) {
      mixItUpArgs.animation.effects = mantleGridOptions.effects;
    }

    if (mantleGridOptions.staggerSequence) {
      mixItUpArgs.animation.staggerSequence = mantleGridOptions.staggerSequence;
    }

    // disable animation for IE11.
    // the lack of autoplacement screws up the calcs.
    if (MantleGrid.IEVersion === 11) {
      mixItUpArgs.animation.enable = false;
      MantleGrid.Placement.ie11_autoplacement($grid, item_selector);
    }

    return mixItUpArgs;
  }

  return MantleGrid;
})(MantleGrid || {});
